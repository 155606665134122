import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import DataApp from './DataContext';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Home from './components/Home';

import BrainTech from './BrainTech';

import About from './components/About';
import Blog from './components/Blog';
import BlogsCategory from './components/BlogsCategory';
import BlogDetail from './components/BlogDetail';
import Career from './components/Career';
import Contact from './components/Contact';
import Learning from './components/Learning';
import Products from './components/Products';
import Services from './components/Services';
import Bde from './components/currrentOpeningDetailPages/Bde';
import CareerForm from './components/currrentOpeningDetailPages/CareerForm';
import JoinUsAsIntern from './components/currrentOpeningDetailPages/JoinUsAsIntern';


import EmployeeManagement from './components/products/EmployeeManagement';
import HospitalManagement from './components/products/HospitalManagement';
import HotelManagement from './components/products/HotelManagement';
import SchoolManagement from './components/products/SchoolManagement';

import CampusAmbassador from './components/learning/CampusAmbassador';
import CollegeInternship from './components/learning/CollegeInternship';
import CollegeWorkshop from './components/learning/CollegeWorkshop';
import CorporateTraining from './components/learning/CorporateTraining';
import CourseInternship from './components/learning/CourseInternship';
import CourseMern from './components/learning/CourseMern';
import CoursePlacement from './components/learning/CoursePlacement';
import CourseRegistration from './components/learning/CourseRegistration';
import MBAInternship from './components/learning/MBAInternship';
import MBAPaidInternship from './components/learning/MBAPaidInternship';
import MockInterview from './components/learning/MockInterview';
import Verification from './components/learning/Verification';
import Testimonials from './components/learning/Testimonials';

function App() {

  return (
    <>
      <BrowserRouter>
        <DataApp>

          {/* <div id="loader" class="loader">
            <div class="loader-container"></div>
        </div> */}

          <div class="main-content">
            <Header />
            <Routes>
              <Route path='/home' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/services' element={<Services />} />
              <Route path='/products' element={<Products />} />
              <Route path='/products/employeemanagement' element={<EmployeeManagement />} />
              {/* <Route path='/employeemanagement' element={<EmployeeManagement />} /> */}
              <Route path='/products/hotelmanagement' element={<HotelManagement />} />
              <Route path='/products/schoolmanagement' element={<SchoolManagement />} />
              <Route path='/products/hospitalmanagement' element={<HospitalManagement />} />
              {/* <Route path='/hospitalmanagement' element={<HospitalManagement />} /> */}

              <Route path='/contact' element={<Contact />} />
              <Route path='/career' element={<Career />} />
              <Route path='/career/currentOpening/bde' element={<Bde />} />
              <Route path='/career/apply' element={< CareerForm/>} />
              <Route path='/career/currentOpening/joinusasintern' element={< JoinUsAsIntern/>} />
              <Route path='/blogs' element={<Blog />} />
              <Route path='/blog/:blogid' element={<BlogDetail />} />
              <Route path='/blogs/category/:categoryname' element={<BlogsCategory />} />
              <Route path='/learning' element={<Learning />} />

              {/* to be developed */}
              <Route path='/learning/internship' element={<CourseInternship />} />
              <Route path='/learning/mbainternship' element={<MBAInternship />} />
              <Route path='/learning/mbapaidinternship' element={<MBAPaidInternship />} />
              <Route path='/learning/mern' element={<CourseMern />} />
              <Route path='/learning/placement' element={<CoursePlacement />} />
              <Route path='/learning/mockinterview' element={<MockInterview />} />
              <Route path='/learning/collegeinternship' element={<CollegeInternship />} />
              <Route path='/learning/corporatetraining' element={<CorporateTraining />} />
              <Route path='/learning/collegeworkshop' element={<CollegeWorkshop />} />
              <Route path='/learning/campusambassador' element={<CampusAmbassador />} />
              <Route path='/learning/registration/:coursename' element={<CourseRegistration />} />
              <Route path='/verification' element={<Verification />} />
              {/* to be developed */}
              
              <Route path='/braintech' element={<BrainTech />} />
              <Route path='/' element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </DataApp>

      </BrowserRouter>
    </>
  );
}

export default App;
