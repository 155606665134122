import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (

        <>

            <div class="rs-banner style4">
                <div class="container">
                    <div class="banner-content">
                        <span class="sub-title">Technology & It Solutions </span>
                        {/* <h1 class="title">Excellent IT services for your success</h1> */}
                        <h1 class="title">One Stop solution for all Web and Software Solutions</h1>
                        <h1 class="title"></h1>
                        <p class="desc">
                            We are Leading Technology Solutions Providing Company all over the world doing over 10 years.
                        </p>
                        <ul class="banner-btn">
                            <li><Link class="readon discover" to="/about">Discover More</Link></li>
                            <li>
                                <div class="rs-videos">
                                    <div class="animate-border white-color">
                                        <a class="popup-border popup-videos" href="#">
                                            <i class="fa fa-play"></i>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div>

                <div class="rs-slider style1">
                    <div class="rs-carousel owl-carousel" data-loop="true" data-items="1" data-margin="0" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="1" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="1" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="1" data-md-device-nav="true" data-md-device-dots="false">
                        <div class="slider-content slide1">
                            <div class="container">
                                <div class="content-part">
                                    <div class="sl-sub-title wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">Top-North Software</div>
                                    <h1 class="sl-title mb-mb-10 wow fadeInRight" data-wow-delay="600ms" data-wow-duration="2000ms">Development</h1>
                                    <div class="sl-desc wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
                                        Using modern technogoly & modern frameworks
                                    </div>
                                    <div class="sl-btn wow fadeInUp" data-wow-delay="200ms" data-wow-duration="3000ms">
                                        <a class="readon learn-more slider-btn" href="about.html">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-content slide2">
                            <div class="container">
                                <div class="content-part text-center">
                                    <div class="sl-sub-title wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">We Increase Your</div>
                                    <h1 class="sl-title mb-mb-10 wow fadeInRight" data-wow-delay="600ms" data-wow-duration="2000ms">Business Success</h1>
                                    <div class="sl-desc fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
                                        Using modern technogoly and IT Services
                                    </div>
                                    <ul class="slider-btn wow fadeInRight" data-wow-delay="200ms" data-wow-duration="3000ms">
                                        <li><a class="readon learn-more slider-btn" href="about.html">Free Consultation</a></li>
                                        <li>
                                            <div class="slider-video">
                                                <a class="popup-videos" href="https://www.youtube.com/watch?v=atMUy_bPoQI">
                                                    <i class="fa fa-play"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div class="rs-services main-home style1 pt-100 md-pt-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 md-mb-30">
                                <div class="services-item">
                                    <div class="services-icon">
                                        <div class="image-part">
                                            <img src="assets/images/services/style1/1.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <div class="services-text">
                                            <h3 class="services-title"><a href="web-development.html">IT Management</a></h3>
                                        </div>
                                        <div class="services-desc">
                                            <p>
                                                Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 md-mb-30">
                                <div class="services-item">
                                    <div class="services-icon">
                                        <div class="image-part">
                                            <img src="assets/images/services/style1/2.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <div class="services-text">
                                            <h3 class="services-title"><a href="web-development.html">Cloud Services</a></h3>
                                        </div>
                                        <div class="services-desc">
                                            <p>
                                                Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 sm-mb-30">
                                <div class="services-item">
                                    <div class="services-icon">
                                        <div class="image-part">
                                            <img src="assets/images/services/style1/3.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <div class="services-text">
                                            <h3 class="services-title"><a href="web-development.html">Data Security</a></h3>
                                        </div>
                                        <div class="services-desc">
                                            <p>
                                                Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="services-item">
                                    <div class="services-icon">
                                        <div class="image-part">
                                            <img src="assets/images/services/style1/4.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <div class="services-text">
                                            <h3 class="services-title"><a href="web-development.html">Machine Learning</a></h3>
                                        </div>
                                        <div class="services-desc">
                                            <p>
                                                Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div class="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 md-mb-50">
                                <div class="images">
                                    <img src="assets/images/about/about-2.png" alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6 pl-60 md-pl-15">
                                <div class="contact-wrap">
                                    <div class="sec-title mb-30">
                                        <div class="sub-text style2">About Us</div>
                                        <h2 class="title pb-38">
                                            We Are Increasing Business Success With Technology
                                        </h2>
                                        <div class="desc pb-35">
                                            Over many years working in IT services developing software applications and mobile apps for clients all over the world.
                                        </div>
                                        <p class="margin-0 pb-15">
                                        We are a bunch of highly technical & creative personnel with innovative ideas and having a crazy attitude towards our work; provides inexpensive yet effective array of total web and software solutions. Along with that we have a complete dedicated team for Training and Workshop purposes. We have expert team of professionals for different technologies who keep them updated with recent innovations in the IT industry.
                                        </p>
                                    </div>
                                    <div class="btn-part">
                                        <Link class="readon learn-more contact-us" to="/contact">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="pt-relative rs-services style4 modify1 services3 gray-color pt-120 md-pt-80">
                    <div class="container">
                        <div class="sec-title2 text-center mb-45">
                            <span class="sub-text">Services</span>
                            <h2 class="title">
                                We Are Offering All Kinds of IT Solutions Services
                            </h2>
                        </div>
                    </div>
                    <div class="bg-section pb-120 md-pb-80">
                        <div class="container">
                            <div class="row gray-color pb-35 pl-25 pr-25 md-pl-0 md-pr-0">
                                <div class="col-lg-4 col-md-6 mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/1.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="software-development.html">Software Development</a></h2>
                                            <p class="desc">
                                            Software development is the process of computer programming, documenting, testing and bug fixing involved in creating and maintaining applications and frameworks involved in a software release life cycle and resulting in a software product.The term refers to a process of writing and maintaining the source code, but in a broader sense of the term it includes all that is involved between the conception of the desired software through to the final manifestation of the software, ideally in a planned and structured process.<br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/2.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="web-development.html">Web Development</a></h2>
                                            <p class="desc">
                                            Web development, a broad term for the work involved in developing a web site for the internet or an intranet. It can range from developing the simplest static single page of plain text to the most complex web-based internet applications, electronic businesses and social network services. A more comprehensive list of tasks to which web development commonly refers, may include web design, web content development, client liaison, client-side/server-side scripting, web server and network security configuration and e-commerce development.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/3.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="analytic-solutions.html">Mobile Applications</a></h2>
                                            <p class="desc">
                                            Mobile application development is the set of processes and procedures involved in writing software for small, wireless computing devices such as smartphones or tablets. Mobile application development has its roots in more traditional software development.Todays’ fast world has led all human activities to shift to their mobile phones. Whether, you want to recharge your phone or purchase gift for your loved ones. Whether you are searching for a taxi or you are planning to buy a new flat, everything is going online.<br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/4.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="cloud-and-devops.html">Clould & DevOps</a></h2>
                                            <p class="desc">
                                            DevOps is a software engineering practice that is suited to cloud computing. In a DevOps environment, developers collaborate with IT operations and other teams. DevOps goes beyond continuous integration and continuous delivery (CI/CD) to enable near-instantaneous deployment of products and services in the cloud. The agility and flexibility of the DevOps framework enables organizations to adapt quickly to marketplace demands and maintain a competitive edge.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 sm-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/5.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="product-design.html">Product & Design</a></h2>
                                            <p class="desc">
                                            Designing a product that solves real customer and market needs would be a lot easier if you could see into the future. You need to know how potential customers will react to a product or if there’s market demand—all before you even start designing.<br/><br/><br/><br/><br/><br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img src="assets/images/services/main-home/icons/6.png" alt="" />
                                        </div>
                                        <div class="services-content">
                                            <h2 class="title"><a href="data-center.html">E Commerce App</a></h2>
                                            <p class="desc">
                                            HashedBit provide all type of E-Commerce Development. Todays’ world is an online world. From basic amenities to luxury items, for all kind of goods and services, we reply on online marketing world. The future is going to be completely online. In this competitive market, physical shops are shifting towards E-Commerce. So, for all such kind of goods, services and business, we provide E-Commerce Development Service.<br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-part text-center mt-65">
                                <Link class="readon learn-more contact-us" to="/services">View All Services</Link>
                            </div>
                        </div>
                        <div class="shape-part">
                            <div class="left-side">
                                <img src="assets/images/services/shape-2.png" alt="" />
                            </div>
                            <div class="right-side">
                                <img src="assets/images/services/shape-3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>




                <div class="rs-call-us bg1 pt-120 md-pt-80">
                    <div class="container">
                        <div class="row rs-vertical-middle">
                            <div class="col-lg-6">
                                <div class="image-part">
                                    <img src="assets/images/call-us/contact-here.png" alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="rs-contact-box text-center">
                                    <div class="address-item mb-25">
                                        <div class="address-icon">
                                            <i class="fa fa-phone"></i>
                                        </div>
                                    </div>
                                    <div class="sec-title3">
                                        <span class="sub-text">CALL US 24/7</span>
                                        <h2 class="title">(+91) 9669787936 </h2>
                                        <p class="desc">Have any idea or project for in your mind call us or schedule a appointment. Our representative will reply you shortly.</p>
                                    </div>
                                    <div class="btn-part mt-40 md-mb-60">
                                        {/* <a class="readon lets-talk" href="contact.html">Let's Talk</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div class="rs-counter main-counter-home">
                    <div class="counter-top-area text-center bg2">
                        <div class="row">
                            <div class="col-lg-4 md-mb-40">
                                <div class="counter-list">
                                    <div class="counter-text">
                                        <div class="count-number">
                                            <span class="rs-count k">80</span>
                                        </div>
                                        <h3 class="title">Happy Clients</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 md-mb-40">
                                <div class="counter-list">
                                    <div class="counter-text">
                                        <div class="count-number">
                                            <span class="rs-count plus">50</span>
                                        </div>
                                        <h3 class="title">Companies</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="counter-list">
                                    <div class="counter-text">
                                        <div class="count-number">
                                            <span class="rs-count plus">230</span>
                                        </div>
                                        <h3 class="title">Projects Done</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div class="rs-process pt-180 pb-120 md-pt-80 md-pb-80">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-4 md-mb-40">
                                <div class="process-wrap bg3">
                                    <div class="sec-title mb-30">
                                        <div class="sub-text new">Working Process</div>
                                        <h2 class="title white-color">
                                            Our Working Process -  How We Work For Our Customers
                                        </h2>
                                    </div>
                                    <div class="btn-part mt-40">
                                        <Link class="readon learn-more contact-us" to="/contact">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 pl-30 md-pl-15">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 mb-40">
                                        <div class="rs-addon-number">
                                            <div class="number-text">
                                                <div class="number-area">
                                                    1.
                                                </div>
                                                <div class="number-title">
                                                    <h3 class="title"> Discovery</h3>
                                                </div>
                                                <p class="number-txt">  We begin every project in the same way by carrying out detailed research to ensure we have a good understanding of our client and their project requirements.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 mb-40">
                                        <div class="rs-addon-number">
                                            <div class="number-text">
                                                <div class="number-area">
                                                    2.
                                                </div>
                                                <div class="number-title">
                                                    <h3 class="title">Planning</h3>
                                                </div>
                                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 sm-mb-40">
                                        <div class="rs-addon-number">
                                            <div class="number-text">
                                                <div class="number-area">
                                                    3.
                                                </div>
                                                <div class="number-title">
                                                    <h3 class="title">Execute</h3>
                                                </div>
                                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="rs-addon-number">
                                            <div class="number-text">
                                                <div class="number-area">
                                                    4.
                                                </div>
                                                <div class="number-title">
                                                    <h3 class="title">Deliver</h3>
                                                </div>
                                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}




                {/* <div class="rs-project bg5 style1 pt-120 md-pt-80">
                    <div class="container">
                        <div class="sec-title2 text-center mb-45 md-mb-30">
                            <span class="sub-text white-color">Project</span>
                            <h2 class="title white-color">
                                We Are Offering All Kinds of IT Solutions Services
                            </h2>
                        </div>
                        <div class="rs-carousel owl-carousel" data-loop="true" data-items="3" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="2" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="3" data-md-device-nav="true" data-md-device-dots="false">
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/1.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Product Design</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/2.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Product Engineering</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/3.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Analytic Solutions</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/4.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Growth Strategies</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/5.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Platform Integration</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                            <div class="project-item">
                                <div class="project-img">
                                    <a href="case-studies-style1.html"><img src="assets/images/project/main-home/6.jpg" alt="images" /></a>
                                </div>
                                <div class="project-content">
                                    <h3 class="title"><a href="case-studies-style1.html">Innovative Interfaces</a></h3>
                                    <span class="category"><a href="case-studies-style1.html">IT Technology</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div class="rs-pricing white-bg pt-255 pb-150 md-pb-80">
                    <div class="container">
                        <div class="sec-title2 text-center mb-45">
                            <span class="sub-text">Pricing</span>
                            <h2 class="title">
                                Our Pricing Plan
                            </h2>
                            <div class="heading-line">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 md-mb-50">
                                <div class="pricing-table new-style">
                                    <div class="pricing-badge">
                                        Silver
                                    </div>
                                    <div class="pricing-icon">
                                        <img src="assets/images/pricing/main-home/icons/1.png" alt="" />
                                    </div>
                                    <div class="pricing-table-price">
                                        <div class="pricing-table-bags">
                                            <span class="pricing-currency">$</span>
                                            <span class="table-price-text">29.99</span>
                                            <span class="table-period">Monthly Package</span>
                                        </div>
                                    </div>
                                    <div class="pricing-table-body">
                                        <ul>
                                            <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                            <li><i class="fa fa-check"></i><span>1 Native Android App</span></li>
                                            <li><i class="fa fa-close"></i><span>Multi-Language Support</span></li>
                                            <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                        </ul>
                                    </div>
                                    <div class="btn-part">
                                        <a class="readon buy-now" href="single-team.html">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 md-mb-50">
                                <div class="pricing-table primary-bg">
                                    <div class="pricing-badge white-color-bg">
                                        Gold
                                    </div>
                                    <div class="pricing-icon">
                                        <img src="assets/images/pricing/main-home/icons/2.png" alt="" />
                                    </div>
                                    <div class="pricing-table-price">
                                        <div class="pricing-table-bags">
                                            <span class="pricing-currency">$</span>
                                            <span class="table-price-text">39.99</span>
                                            <span class="table-period">Monthly Package</span>
                                        </div>
                                    </div>
                                    <div class="pricing-table-body">
                                        <ul>
                                            <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                            <li><i class="fa fa-check"></i><span>2 Native Android App</span></li>
                                            <li><i class="fa fa-check"></i><span>Multi-Language Support</span></li>
                                            <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                        </ul>
                                    </div>
                                    <div class="btn-part">
                                        <a class="readon buy-now" href="single-team.html">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="pricing-table new-style">
                                    <div class="pricing-badge">
                                        Platinum
                                    </div>
                                    <div class="pricing-icon">
                                        <img src="assets/images/pricing/main-home/icons/3.png" alt="" />
                                    </div>
                                    <div class="pricing-table-price">
                                        <div class="pricing-table-bags">
                                            <span class="pricing-currency">$</span>
                                            <span class="table-price-text">79.99</span>
                                            <span class="table-period">Monthly Package</span>
                                        </div>
                                    </div>
                                    <div class="pricing-table-body">
                                        <ul>
                                            <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                            <li><i class="fa fa-check"></i><span>3 Native Android App</span></li>
                                            <li><i class="fa fa-check"></i><span>Multi-Language Support</span></li>
                                            <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                        </ul>
                                    </div>
                                    <div class="btn-part">
                                        <a class="readon buy-now" href="single-team.html">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div class="rs-testimonial main-home style2 bg5 pt-120 pb-120 md-pt-80 md-pb-80">
                    <div class="container">
                        <div class="sec-title2 text-center mb-45">
                            <span class="sub-text white-color">Testimonial</span>
                            <h2 class="title testi-title white-color">
                                What Saying Our Customers
                            </h2>
                        </div>
                        <div class="rs-carousel owl-carousel"
                            data-loop="true"
                            data-items="2"
                            data-margin="30"
                            data-autoplay="true"
                            data-hoverpause="true"
                            data-autoplay-timeout="5000"
                            data-smart-speed="800"
                            data-dots="true"
                            data-nav="false"
                            data-nav-speed="false"

                            data-md-device="2"
                            data-md-device-nav="false"
                            data-md-device-dots="true"
                            data-center-mode="false"

                            data-ipad-device2="1"
                            data-ipad-device-nav2="false"
                            data-ipad-device-dots2="true"

                            data-ipad-device="1"
                            data-ipad-device-nav="false"
                            data-ipad-device-dots="true"

                            data-mobile-device="1"
                            data-mobile-device-nav="false"
                            data-mobile-device-dots="false">
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/1.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Mariya Khan</a>
                                    <span class="designation">CEO, Brick Consulting</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/2.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Sonia Akhter</a>
                                    <span class="designation">Graphic Designer</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/3.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Deluar Hossen</a>
                                    <span class="designation">Web Developer</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/4.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Asif Ahmed</a>
                                    <span class="designation">App Developer</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/1.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Mariya Khan</a>
                                    <span class="designation">CEO, Brick Consulting</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/2.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Sonia Akhter</a>
                                    <span class="designation">Graphic Designer</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/3.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Deluar Hossen</a>
                                    <span class="designation">Web Developer</span>
                                </div>
                            </div>
                            <div class="testi-item">
                                <div class="author-desc">
                                    <div class="desc"><img class="quote" src="assets/images/testimonial/main-home/quote-white.png" alt="" />Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway.</div>
                                    <div class="author-img">
                                        <img src="assets/images/testimonial/main-home/4.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="author-part">
                                    <a class="name" href="#">Asif Ahmed</a>
                                    <span class="designation">App Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* <div id="rs-blog" class="rs-blog pb-120 pt-120 md-pt-80 md-pb-80">
                    <div class="container">
                        <div class="sec-title2 text-center mb-45">
                            <span class="sub-text">Blogs</span>
                            <h2 class="title testi-title">
                                Read Our Latest Tips & Tricks
                            </h2>
                            <div class="heading-line">

                            </div>
                        </div>
                        <div class="rs-carousel owl-carousel" data-loop="true" data-items="3" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="2" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="3" data-md-device-nav="false" data-md-device-dots="false">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/1.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html">Software Development</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 16 Nov 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Necessity May Give Us Your Best Virtual Court System</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/2.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html"> Web Development</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 20 December 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Tech Products That Makes Its Easier to Stay at Home</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/3.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html">It Services</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 22 December 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Open Source Job Report Show More Openings Fewer</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/4.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html">Artifical Intelligence</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 26 December 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Types of Social Proof What its Makes Them Effective</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/5.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html">Digital Technology</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 28 December 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Tech Firms Support Huawei Restriction, Balk at Cost</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="blog-details.html"><img src="assets/images/blog/main-home/6.jpg" alt="" /></a>
                                    <ul class="post-categories">
                                        <li><a href="blog-details.html">It Services</a></li>
                                    </ul>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li class="date"><i class="fa fa-calendar-check-o"></i> 30 December 2020</li>
                                        <li class="admin"><i class="fa fa-user-o"></i> admin</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details.html">Servo Project Joins The Linux Foundation Fold Desco</a></h3>
                                    <p class="desc">We denounce with righteous indige nation and dislike men who are so beguiled...</p>
                                    <div class="blog-button"><a href="blog-details.html">Learn More</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}





                {/* <div class="rs-partner pt-80 pb-70">
                    <div class="container">
                        <div class="rs-carousel owl-carousel" data-loop="true" data-items="5" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="2" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="3" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="5" data-md-device-nav="false" data-md-device-dots="false">
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/1.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/1.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/2.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/2.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/3.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/3.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/4.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/4.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/5.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/5.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/6.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/6.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/7.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/7.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/8.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/8.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="partner-item">
                                <div class="logo-img">
                                    <a href="https://rstheme.com/">
                                        <img class="hover-logo" src="assets/images/partner/9.png" alt="" />
                                        <img class="main-logo" src="assets/images/partner/9.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



            </div>

        </>
    )
}

export default Home
