import React from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
import hotel from "../../components/products/hotel.png";


const HotelManagement = () => {
  return (
    <>
      <div class="rs-breadcrumbs img3">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Hotel Management System</h1>
          {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Home</a>
                        </li>
                        <li>Services</li>                       
                    </ul> */}
        </div>
      </div>

      {/* <div className="contact-section bg-home3 py-6">
        <div className="container my-5">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                Hotel <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={hotel}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  py-5">
            <div className="col  ">
              <div className=" ps-5 pe-3">
                <div className=" " style={{maxWidth: " 600px"}}>
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">Hotel Management System</h1>
                  <p className="text-wrap text-justify">
                    Transform your school's management process through our
                    school management system. It is a cloud-based system that
                    eases the management of all the data from student details to
                    exam results and tasks like admission and staff management.
                    It provides a dashboard to access data.
                  </p>
                  <p className="text-wrap text-justify">
                    School administration has the responsibility of managing all
                    the affairs, but it's a tedious task and errors are
                    inevitable in handling huge amounts of data. Our school's
                    management system is a boon for the management people, it
                    makes their work hassle-free and enhances work productivity.
                  </p>
                  <p className="text-wrap text-justify">
                    We curate management systems that suit the requirements of
                    the institute and then digitize their day-to-day processes.
                    With multiple modules and seamless functioning, and secure
                    operations, we deliver the best solution for school
                    management.
                  </p>
                  <p className="text-wrap text-justify">
                    It not only makes the process easy but also saves money by
                    effectively managing the fees and expenses and enabling the
                    management people to track the finances and analyze the
                    expenditures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mt-3 ms-5" style={{ maxWidth: "500px" }}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  {/* <!-- div to show reCAPTCHA --> */}
      {/* <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div class="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 md-mb-30">
              <div class="sec-title mb-40">
                <div class="sub-text style4-bg left">Product</div>
                <h2 class="title pb-20">
                  Hotel Management System
                </h2>
                <p className="text-wrap text-justify">
                  Transform your school's management process through our
                  school management system. It is a cloud-based system that
                  eases the management of all the data from student details to
                  exam results and tasks like admission and staff management.
                  It provides a dashboard to access data.
                </p>
                <p className="text-wrap text-justify">
                  School administration has the responsibility of managing all
                  the affairs, but it's a tedious task and errors are
                  inevitable in handling huge amounts of data. Our school's
                  management system is a boon for the management people, it
                  makes their work hassle-free and enhances work productivity.
                </p>
                <p className="text-wrap text-justify">
                  We curate management systems that suit the requirements of
                  the institute and then digitize their day-to-day processes.
                  With multiple modules and seamless functioning, and secure
                  operations, we deliver the best solution for school
                  management.
                </p>
                <p className="text-wrap text-justify">
                  It not only makes the process easy but also saves money by
                  effectively managing the fees and expenses and enabling the
                  management people to track the finances and analyze the
                  expenditures.
                </p>

              </div>
            </div>
            <div class="col-xl-6 col-lg-6">

              <img
                src={hotel}
                alt="img"
                className="img-responsive"
              />

              {/* <div class="rs-contact mod1">
                <div class="contact-wrap">
                  <div class="content-part mb-25">
                    <h2 class="title mb-15">Schedule Appointment</h2>
                    <p class="desc">We here to help you 24/7 with experts</p>
                  </div>
                  <div id="appointment-messages"></div>
                  <form action="" method="">
                    <div className=" my-4">
                      <label>Name</label>
                      <input
                        type="text"
                        required=""
                        className="form-control"
                        name="contact_name"
                        placeholder="Name"
                      />
                    </div>
                    <div className=" mt-4">
                      <label>Email</label>
                      <input
                        type="email"
                        required=""
                        className="form-control"
                        name="contact_email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label>Phone</label>
                      <input
                        type="tel"
                        required=""
                        className="form-control"
                        name="contact_phone"
                        placeholder="+91"
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label>Your Query</label>
                      <textarea
                        type="Subject"
                        required=""
                        className="form-control"
                        name="message"
                        placeholder="Your Query"
                      ></textarea>
                    </div>

                    <div
                      className="g-recaptcha mt-4"
                      data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                    >
                      <div style={{ width: "304px", height: "78px" }}>
                        <div>
                          <iframe
                            title="reCAPTCHA"
                            width="304"
                            height="78"
                            role="presentation"
                            name="a-tnvqec2kjuv"
                            frameborder="0"
                            scrolling="no"
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                            src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                          ></iframe>
                        </div>
                        <textarea
                          id="g-recaptcha-response-1"
                          name="g-recaptcha-response"
                          className="g-recaptcha-response"
                          style={{
                            width: " 250px",
                            height: "40px",
                            border: "1px solid rgb(193, 193, 193)",
                            margin: " 10px 25px",
                            padding: " 0px",
                            resize: "none",
                            display: " none",
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <br />

                    <div className=" d-flex justify-content-center">
                      <input
                        type="submit"
                        className="btn btn-primary btn-lg mx-1 "
                        style={{ width: "280px" }}
                        name="submit_btn"
                        value="Submit Now"
                      />
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div class="line-inner">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      {/* <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">Features</h2>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white "> Great customer experience</h5>
                  <p>
                    A simple and easy navigation design helps you clearly
                    display your menu and details and provides a good user
                    experience. Customers can easily browse and order their
                    favorite dishes. They can also add items to the cart and
                    mark them as favorites. The feedback options allow customers
                    to give ratings and suggestions. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Get the best restaurant POS</h5>
                  <p>
                    Our POS software assists you in managing your restaurant
                    efficiently and enables you to grow and expand. It Improves
                    billings possess and enhances overall operational efficiency
                    through advanced features. You can manage everything from
                    what your customers see to your orders and inventory from
                    your dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">
                    {" "}
                    Production and recipe management
                  </h5>
                  <p>
                    Improve accuracy in your kitchen. Take control of your
                    recipes by mapping them to their ingredients to ensure a
                    consistent taste. Manage prices effectively by calculating
                    production costs incurred in the most simple way using our
                    restaurant software
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Intelligent analytics</h5>
                  <p>
                    Simple and actionable reports to manage your day-to-day
                    operations smoothly. You can generate real-time reports to
                    analyze your business trends to help you make better
                    decisions. Get custom reports on sales, ingredients usage,
                    and daily pilferage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Manage inventory</h5>
                  <p>
                    The restaurant management system allows you to efficiently
                    manage your inventory by ordering on time and reducing
                    wastage. You can monitor the stock and place orders. The
                    system helps you in maintaining your inventory so that you
                    never fall short of your primary supplies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Manage inventory</h5>
                  <p>
                    The restaurant management system allows you to efficiently
                    manage your inventory by ordering on time and reducing
                    wastage. You can monitor the stock and place orders. The
                    system helps you in maintaining your inventory so that you
                    never fall short of your primary supplies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div class="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text"></span>
            <h2 class="title testi-title">
              Features
            </h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="software-development.html">Great customer experience</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      A simple and easy navigation design helps you clearly
                      display your menu and details and provides a good user
                      experience. Customers can easily browse and order their
                      favorite dishes. They can also add items to the cart and
                      mark them as favorites. The feedback options allow customers
                      to give ratings and suggestions.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        01 
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Get the best restaurant POS</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Our POS software assists you in managing your restaurant
                      efficiently and enables you to grow and expand. It Improves
                      billings possess and enhances overall operational efficiency
                      through advanced features. You can manage everything from
                      what your customers see to your orders and inventory from
                      your dashboard.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        02 
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="analytic-solutions.html">{" "}
                      Production and recipe management</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Improve accuracy in your kitchen. Take control of your
                      recipes by mapping them to their ingredients to ensure a
                      consistent taste. Manage prices effectively by calculating
                      production costs incurred in the most simple way using our
                      restaurant software
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        03 
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="cloud-and-devops.html">Intelligent analytics</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Simple and actionable reports to manage your day-to-day
                      operations smoothly. You can generate real-time reports to
                      analyze your business trends to help you make better
                      decisions. Get custom reports on sales, ingredients usage,
                      and daily pilferage.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        04 
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item purple-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="product-design.html">Manage inventory</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      The restaurant management system allows you to efficiently
                      manage your inventory by ordering on time and reducing
                      wastage. You can monitor the stock and place orders. The
                      system helps you in maintaining your inventory so that you
                      never fall short of your primary supplies.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        05 
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Manage inventory</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      The restaurant management system allows you to efficiently
                      manage your inventory by ordering on time and reducing
                      wastage. You can monitor the stock and place orders. The
                      system helps you in maintaining your inventory so that you
                      never fall short of your primary supplies.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        06 
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <section className="  py-5 ps-5  ">
        <div className="container">
          <div className="row">
            <h4 className="text-danger"> why choose us</h4>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>360 Approach</h4>
              <p className="text-wrap text-justify">
                End-to-end hospital management solutions with dynamic modules to
                meet every need for efficient hospital operations and
                comprehensive management.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Tested and Proven</h4>
              <p>
                Corewave’s HMS is developed and tested by highly experienced
                professionals to ensure flawless and reliable functioning.
                Trusted by reputed healthcare providers we are best-in-class
                management software
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Highly Scalable</h4>
              <p>
                A scalable software to provide you best solutions as you grow.
                Our solution expands along with your working needs.{" "}
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>User friendly</h4>
              <p>
                Designed to suit customer needs, our HMS is flexible and easy to
                use. Functions are included while keeping the requirements in
                mind to provide maximum efficiency.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Cost effective</h4>
              <p>
                A highly useful and robust software at the most reasonable cost.
                We aim to provide the best technology solutions at the best
                prices.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Join the transformation</h4>
              <p>
                We are eager to support the crucial process of digital
                transformation within your healthcare organization. For all your
                software requirements we are here to provide you most relevant
                solutions
              </p>
            </div>
            <h4 className="my-3">Customise solution</h4>
            <h4 className="my-3">Quick execution</h4>
            <h4 className="my-3">Complete the Test and Display</h4>
            <h4 className="mt-3 mb-5">Speedy Setup</h4>
          </div>
        </div>
      </section> */}
      <div class="rs-services main-home style3 bg13 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container pt-relative">
          <div class="sec-left">
            <h2 class="title wow fadeInDown">why choose us</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">360 Approach</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      End-to-end hospital management solutions with dynamic modules to
                      meet every need for efficient hospital operations and
                      comprehensive management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/2.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Tested and Proven</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Corewave’s HMS is developed and tested by highly experienced
                      professionals to ensure flawless and reliable functioning.
                      Trusted by reputed healthcare providers we are best-in-class
                      management software
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/3.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Highly Scalable</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      A scalable software to provide you best solutions as you grow.
                      Our solution expands along with your working needs.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 md-mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">User friendly</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Designed to suit customer needs, our HMS is flexible and easy to
                      use. Functions are included while keeping the requirements in
                      mind to provide maximum efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Cost effective</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      A highly useful and robust software at the most reasonable cost.
                      We aim to provide the best technology solutions at the best
                      prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Join the transformation</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      We are eager to support the crucial process of digital
                      transformation within your healthcare organization. For all your
                      software requirements we are here to provide you most relevant
                      solutions
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Customise solution</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Quick execution</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Complete the Test and Display</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Speedy Setup</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div> */}
      <div class="rs-call-action bg8 pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center">
            <span class="sub-text white-color">Let's Work Together</span>
            <h2 class="title title4 white-color">
              Need A Succesful Project?
            </h2>
            <div class="call-btn mt-30">
              <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
                Estimate project
              </button>

            </div>
          </div>
        </div>
      </div>
    </>

  );
};
export default HotelManagement;
