import React from 'react'
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaLocationArrow } from "react-icons/fa";
import logo from '../../assets/img/hb_logo.png';

// import logo from '../assets/workshop/CodeAce-Header.png';
const Footer = () => {
    return (
        <>
            <footer id="rs-footer" class="rs-footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-12 col-sm-12 footer-widget">
                                <div class="footer-logo mb-30">
                                    <Link to="#"><img src={logo} /></Link>
                                </div>
                                {/* <h4>HashedBit Innovations Pvt Ltd</h4> */}
                                <div class="textwidget pb-30">
                                    <p>
                                        HashedBit Innovations works with innovative ideas and having a crazy attitude towards our work; provides inexpensive yet effective array of total web and software solutions.
                                    </p>
                                </div>

                                <ul class="footer-social md-mb-30">
                                    <li>
                                        <a class="icon-tilinkedin" href="https://www.linkedin.com/company/hashedbit-innovations/" target="_blank"><span><i class="fa fa-linkedin"></i></span></a>
                                    </li>
                                    <li>
                                        <a class="icon-tifacebook" href="https://www.facebook.com/hashedbitinnovations" target="_blank"><span><i class="fa fa-facebook"></i></span></a>
                                    </li>
                                    <li>
                                        <a class="icon-titwitter" href="https://twitter.com/HashedBit" target="_blank"><span><i class="fa fa-twitter"></i></span></a>
                                    </li>


                                    <li>
                                        <a class="icon-tiinstagram" href="https://www.instagram.com/hashedbit/" target="_blank"><span><i class="fa fa-instagram"></i></span></a>
                                    </li>

                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                                <h3 class="widget-title">Products</h3>
                                <ul class="site-map">
                                    <li><Link to="/products/employeemanagement">Employee Management</Link></li>
                                    <li><Link to="/products/hotelmanagement">Hotel Management</Link></li>
                                    <li><Link to="/products/schoolmanagement">School Management</Link></li>
                                    <li><Link to="/products/hospitalmanagement">Hospital Management</Link></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                                <h3 class="widget-title">Learning</h3>
                                <ul class="site-map">
                                    <li><Link to="/learning/internship">Tech Internship</Link></li>
                                    <li><Link to="/learning/mbainternship">MBA Internship</Link></li>
                                    <li><Link to="/learning/mern">MERN Stack</Link></li>
                                    <li><Link to="/learning/placement">Placement Training</Link></li>
                                    <li><Link to="/learning/collegeinternship">College Internship</Link></li>
                                </ul>
                            </div>


                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <h3 class="widget-title">Contact Info</h3>
                                <ul class="address-widget">
                                    <li>
                                        <i class="flaticon-location"></i>
                                        <div class="desc">Gurgaon, Haryana, India</div>
                                    </li>
                                    <li>
                                        <i class="flaticon-call"></i>
                                        <div class="desc">
                                            <a href="#">+91 - 9669787936</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-call"></i>
                                        <div class="desc">
                                            <a href="#">+91 - 9711543480</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-email"></i>
                                        <div class="desc">
                                            <a href="#">General - info@hashedbit.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-email"></i>
                                        <div class="desc">
                                            <a href="#">HR - hr@hashedbit.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-email"></i>
                                        <div class="desc">
                                            <a href="#">General - hashedbit@gmail.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-email"></i>
                                        <div class="desc">
                                            <a href="#">HR - hashedbitinnovations@gmail.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="flaticon-clock-1"></i>
                                        <div class="desc">
                                            Opening Hours: 10 AM - 7 PM
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <div class="col-lg-3 col-md-12 col-sm-12">
                            <h3 class="widget-title">Newsletter</h3>
                            <p class="widget-desc">We denounce with righteous and in and dislike men who are so beguiled and demo realized.</p>
                            <p>
                                <input type="email" name="EMAIL" placeholder="Your email address" required=""/>
                                <em class="paper-plane"><input type="submit" value="Sign up"/></em>
                                <i class="flaticon-send"></i>
                            </p>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row y-middle">
                            <div class="col-lg-6 text-right md-mb-10 order-last">
                                <ul class="copy-right-menu">
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/products">Products</Link></li>
                                    <li><Link to="/learning">Learning</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/career">Career</Link></li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="copyright">
                                    <p>
                                        Copyright &copy;{new Date().getFullYear()} All rights reserved | <Link to="/">HashedBit Innovations Pvt Ltd</Link>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>



            <div id="scrollUp" class="orange-color">
                <i class="fa fa-angle-up"></i>
            </div>


            <div aria-hidden="true" class="modal fade search-modal" role="dialog" tabindex="-1">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="flaticon-cross"></span>
                </button>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="search-block clearfix">
                            <form>
                                <div class="form-group">
                                    <input class="form-control" placeholder="Search Here..." type="text" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer
