import React from "react";
import hms from "../../components/products/Hospital_management_system.png"
const HospitalManagement = () => {
  return (
    <>
      <div class="rs-breadcrumbs img3">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Hospital Management System</h1>
          {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Home</a>
                        </li>
                        <li>Services</li>                       
                    </ul> */}
        </div>
      </div>

      {/* <div className="contact-section bg-home3 py-6">
        <div className="container my-5">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                Hospital <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={hms}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  py-5">
            <div className="col  ">
              <div className="">
                <div className=" " style={{ maxWidth: "640px" }}>
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">Hospital Management System</h1>
                  <p className="text-wrap text-justify">
                    Manage healthcare operations effectively with our advanced
                    hospital management system. The customizable, AI-based
                    software helps you manage every aspect of your hospital or
                    clinic. It provides end-to-end management, from patients and
                    billing to laboratories and OT, and enables hospital
                    administration to have centralized control of all the
                    activities
                  </p>
                  <p className="text-wrap text-justify">
                    Switch to automation with our state-of-the-art HMS and
                    manage all the processes with efficiency. This modern
                    technology software allows you to easily control daily
                    hospital activities and provides insightful reports and
                    analytics. The cloud-based system safely stores all the data
                    so that you can access any information, in no time. It
                    digitizes the processes, reducing the hassle of handling
                    papers and enabling fast and effective services.
                  </p>
                  <p className="text-wrap text-justify">
                    Its automation feature speeds up processes and enables you
                    to manage all the details and documents in one place. Proper
                    and timely management activities lead to better service
                    delivery and good patient experience.
                  </p>
                  <p className="text-wrap text-justify">
                    The software stores a large database from where any data or
                    document can be easily accessed. Every detail of the
                    patient, their stocks, pharmacy, doctors, and other
                    information are securely stored. It provides robust data
                    storage and a backup facility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mt-3 ms-3" style={{ maxWidth: "500px" }}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  //  {/* <!-- div to show reCAPTCHA -->*}
                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>  */}
      {/* new Choose section  */}
      <div class="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 md-mb-30">
              <div class="sec-title mb-40">
                <div class="sub-text style4-bg left">Product</div>
                <h2 class="title pb-20">
                  Hospital Management System
                </h2>
                <div class="desc">
                  <p className="text-wrap text-justify">
                    Manage healthcare operations effectively with our advanced
                    hospital management system. The customizable, AI-based
                    software helps you manage every aspect of your hospital or
                    clinic. It provides end-to-end management, from patients and
                    billing to laboratories and OT, and enables hospital
                    administration to have centralized control of all the
                    activities
                  </p>
                  <p className="text-wrap text-justify">
                    Switch to automation with our state-of-the-art HMS and
                    manage all the processes with efficiency. This modern
                    technology software allows you to easily control daily
                    hospital activities and provides insightful reports and
                    analytics. The cloud-based system safely stores all the data
                    so that you can access any information, in no time. It
                    digitizes the processes, reducing the hassle of handling
                    papers and enabling fast and effective services.
                  </p>
                  <p className="text-wrap text-justify">
                    Its automation feature speeds up processes and enables you
                    to manage all the details and documents in one place. Proper
                    and timely management activities lead to better service
                    delivery and good patient experience.
                  </p>
                  <p className="text-wrap text-justify">
                    The software stores a large database from where any data or
                    document can be easily accessed. Every detail of the
                    patient, their stocks, pharmacy, doctors, and other
                    information are securely stored. It provides robust data
                    storage and a backup facility.
                  </p>

                </div>
              </div>
              {/* <div class="services-wrap mb-25">
                                <div class="services-icon">
                                    <img src="assets/images/choose/icons/style2/1.png" alt=""/>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Email</a></h3>
                                    <p class="services-txt">info@yourmail.com</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                    <img src="assets/images/choose/icons/style2/2.png" alt=""/>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Call Us</a></h3>
                                    <p class="services-txt"> (+01) 555 - 9811 - 444</p>
                                </div>
                            </div>
                            <div class="services-wrap">
                                <div class="services-icon">
                                    <img src="assets/images/choose/icons/style2/3.png" alt=""/>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Office Address</a></h3>
                                    <p class="services-txt">374 FA Tower, William S Blvd<br/> 2721, IL, USA</p>
                                </div>
                            </div> */}
            </div>
            {/* <div class="col-xl-4 col-lg-5">
                            <div class="rs-contact mod1">
                                <div class="contact-wrap">
                                   <div class="content-part mb-25">
                                       <h2 class="title mb-15">Schedule Appointment</h2>
                                       <p class="desc">We here to help you 24/7 with experts</p>
                                   </div>
                                    <div id="appointment-messages"></div>

                                    <form id="appointment-form" method="post" action="https://rstheme.com/products/html/braintech/appointment.php">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="text" id="appointment_name" name="appointment_name" placeholder="Name" required=""/>
                                                </div> 
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="text" id="appointment_email" name="appointment_email" placeholder="E-Mail" required=""/>
                                                </div>   
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="text" id="appointment_phone" name="appointment_phone" placeholder="Phone Number" required=""/>
                                                </div>   
                                                <div class="col-lg-12 mb-25">
                                                    <input class="from-control" type="text" id="appointment_website" name="appointment_website" placeholder="Your Website" required=""/>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <input class="submit-btn" type="submit" value="Submit Now"/>
                                            </div>
                                        </fieldset>
                                    </form> 
                                </div>
                            </div>
                        </div>  */}
            {/* form section added */}
            <div className="col-xl-6 col-lg-6">

              <img
                src={hms}
                alt="img"
                className="img-responsive"
              />
              {/* <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We are here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div> */}
            </div>

          </div>
        </div>
        <div class="line-inner">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      {/* <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">Feature</h2>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content " style={{maxWidth: " 380px"}}>
                  <h5 className="text-white "> Doctors module</h5>
                  <p>
                    This module contains details of the schedules, duties, and
                    appointments of doctors. Doctors can easily check and
                    monitor their activities to that they don't miss any
                    appointments. Check appointments Get schedule notifications
                    Apply for leave Access records
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Patient Management</h5>
                  <p>
                    It helps to manage patients from appointments to admission
                    and discharge, you can efficiently manage every aspect of
                    the patient lifecycle. Besides, it combines all the details
                    on one platform and ensures proper bill closure. Patient
                    Registration Appointment Queue Management Billing Admission
                    Ward Management Discharge
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> OT Management</h5>
                  <p>
                    Check OT availability and keep records of the operation
                    schedule through the HMS. Automate the whole OT management
                    process and ensure smooth operations. OT schedule Patient
                    detail integration Doctors module integration Automated
                    scheduling
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Pathology management</h5>
                  <p>
                    Manage your orders, generate reports automatically, and
                    enable smart notifications. Additionally, pathologists can
                    get detailed reports and analytics through the HMS. Patient
                    details Test details Billing Online booking Sample
                    Collection Flexible management Automated processing Report
                    generation Dispatch details Analytics
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Pharmacy module</h5>
                  <p>
                    Our HMS assist pharmacies to manage their sales,
                    distribution, and financial data. This software covers the
                    entire pharmacy management lifecycle, from the point of sale
                    to the back office. Purchase Management supplier management
                    Payment Managemen Stock records Low Stock Alerts Expiry
                    details Sales and Return Management Easy communication
                    through SMS/Emails Sales Dashboard
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Billing module</h5>
                  <p>
                    Integrated billing that ensures accurate and smooth workflow
                    You can manage bills from various departments and for
                    different treatments in a single place. Maintain details of
                    separate bills and get alerts for bill dues. Accurate
                    billing Revenue management Quick bill closure and clearance
                    Access records
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white"> Blood bank module</h5>
                  <p>
                    Our intelligent HMS makes crucial processes like blood bank
                    management more efficient. It ensures timely blood
                    requirement delivery through quick record access and an
                    integrated system that makes finding the right blood group
                    easy. Blood bank records Integrated with other modules Shows
                    blood group availability Alerts for low stock Doner and
                    receiver records
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Payroll module</h5>
                  <p>
                    It is important to effectively manage the payrolls of the
                    staff and doctors to ensure their satisfaction and maintain
                    accounting accuracy. Our HMS automated the payroll
                    management process and helps with flawless management.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content" style={{maxWidth: " 380px"}}>
                  <h5 className="text-white">Blood bank module</h5>
                  <p >
                    Our intelligent HMS makes crucial processes like blood bank
                    management more efficient. It ensures timely blood
                    requirement delivery through quick record access and an
                    integrated system that makes finding the right blood group
                    easy. Blood bank records Integrated with other modules Shows
                    blood group availability Alerts for low stock Doner and
                    receiver records
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}



      {/* new feature section */}
      <div class="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text">Services</span>
            <h2 class="title testi-title">
              Our Featured Services
            </h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="software-development.html"> Doctors module</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      This module contains details of the schedules, duties, and
                      appointments of doctors. Doctors can easily check and
                      monitor their activities to that they don't miss any
                      appointments. Check appointments Get schedule notifications
                      Apply for leave Access records
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            01 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html"> Patient Management</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      It helps to manage patients from appointments to admission
                      and discharge, you can efficiently manage every aspect of
                      the patient lifecycle. Besides, it combines all the details
                      on one platform and ensures proper bill closure. Patient
                      Registration Appointment Queue Management Billing Admission
                      Ward Management Discharge
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            02 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="analytic-solutions.html"> OT Management</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Check OT availability and keep records of the operation
                      schedule through the HMS. Automate the whole OT management
                      process and ensure smooth operations. OT schedule Patient
                      detail integration Doctors module integration Automated
                      scheduling
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            03 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="cloud-and-devops.html">Pathology management</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Manage your orders, generate reports automatically, and
                      enable smart notifications. Additionally, pathologists can
                      get detailed reports and analytics through the HMS. Patient
                      details Test details Billing Online booking Sample
                      Collection Flexible management Automated processing Report
                      generation Dispatch details Analytics
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            04 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item purple-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="product-design.html">Pharmacy module</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Our HMS assist pharmacies to manage their sales,
                      distribution, and financial data. This software covers the
                      entire pharmacy management lifecycle, from the point of sale
                      to the back office. Purchase Management supplier management
                      Payment Managemen Stock records Low Stock Alerts Expiry
                      details Sales and Return Management Easy communication
                      through SMS/Emails Sales Dashboard
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            05 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Billing Module</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Integrated billing that ensures accurate and smooth workflow
                      You can manage bills from various departments and for
                      different treatments in a single place. Maintain details of
                      separate bills and get alerts for bill dues. Accurate
                      billing Revenue management Quick bill closure and clearance
                      Access records
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            06 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Blood bank module</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>   Our intelligent HMS makes crucial processes like blood bank
                      management more efficient. It ensures timely blood
                      requirement delivery through quick record access and an
                      integrated system that makes finding the right blood group
                      easy. Blood bank records Integrated with other modules Shows
                      blood group availability Alerts for low stock Doner and
                      receiver records.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        07
                                    </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="cloud-and-devops.html">Payroll module</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>It is important to effectively manage the payrolls of the
                      staff and doctors to ensure their satisfaction and maintain
                      accounting accuracy. Our HMS automated the payroll
                      management process and helps with flawless management.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                        08 
                                    </div> */}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      {/* <section className="  py-5 ps-5  ">
        <div className="container">
          {" "}
          <div className="row">
            <h4 className="text-danger"> why choose us</h4>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>360 Approach</h4>
              <p className="text-wrap text-justify">
                End-to-end hospital management solutions with dynamic modules to
                meet every need for efficient hospital operations and
                comprehensive management.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Tested and Proven</h4>
              <p>
                Corewave’s HMS is developed and tested by highly experienced
                professionals to ensure flawless and reliable functioning.
                Trusted by reputed healthcare providers we are best-in-class
                management software
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Highly Scalable</h4>
              <p>
                A scalable software to provide you best solutions as you grow.
                Our solution expands along with your working needs.{" "}
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>User friendly</h4>
              <p>
                Designed to suit customer needs, our HMS is flexible and easy to
                use. Functions are included while keeping the requirements in
                mind to provide maximum efficiency.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Cost effective</h4>
              <p>
                A highly useful and robust software at the most reasonable cost.
                We aim to provide the best technology solutions at the best
                prices.
              </p>
            </div>
            <div className="my-4 " style={{ maxWidth: " 600px" }}>
              <h4>Join the transformation</h4>
              <p>
                We are eager to support the crucial process of digital
                transformation within your healthcare organization. For all your
                software requirements we are here to provide you most relevant
                solutions
              </p>
            </div>
            <h4 className="my-3">Customise solution</h4>
            <h4 className="my-3">Quick execution</h4>
            <h4 className="my-3">Complete the Test and Display</h4>
            <h4 className="mt-3 mb-5">Speedy Setup</h4>
          </div>
        </div>
      </section> */}
      {/* new why choose us section */}
      <div class="rs-services main-home style3 bg13 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container pt-relative">
          <div class="sec-left">
            <h2 class="title wow fadeInDown">Why Choose Us</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">360 Approach</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      End-to-end hospital management solutions with dynamic modules to
                      meet every need for efficient hospital operations and
                      comprehensive management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/2.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Tested and Proven</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Corewave’s HMS is developed and tested by highly experienced
                      professionals to ensure flawless and reliable functioning.
                      Trusted by reputed healthcare providers we are best-in-class
                      management software
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/3.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Highly Scalable</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      A scalable software to provide you best solutions as you grow.
                      Our solution expands along with your working needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 md-mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">User Friendly</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Designed to suit customer needs, our HMS is flexible and easy to
                      use. Functions are included while keeping the requirements in
                      mind to provide maximum efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Cost Effective</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      A highly useful and robust software at the most reasonable cost.
                      We aim to provide the best technology solutions at the best
                      prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Remote Assistance</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      We are eager to support the crucial process of digital
                      transformation within your healthcare organization. For all your
                      software requirements we are here to provide you most relevant
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div> */}
      {/* new section added for project */}
      <div class="rs-call-action bg8 pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center">
            <span class="sub-text white-color"> Let's Work Together</span>
            <h2 class="title title4 white-color">
              Need A Succesful Project?
            </h2>
            <div class="call-btn mt-30">
              <a class="readon discover started" href="contact.html">Estimate project</a>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default HospitalManagement;
