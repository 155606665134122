
import axios from 'axios';
import React, { useState } from 'react';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Perform form submission logic here
        console.log(formData);
        try {
            const url = process.env.REACT_APP_API_URL + 'contact/register';
            const response = await axios.post(url, {
                name: formData.name,
                email: formData.email,
                contact: formData.contact,
                subject: formData.subject,
                message: formData.message
            });
            const responseData = response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return (
        <>
        <div class="rs-breadcrumbs img3">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Contact Us</h1>
                    {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Home</a>
                        </li>
                        <li>Services</li>                       
                    </ul> */}
                </div>
            </div>

            <div class="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-7 md-mb-30">
                        <div class="sec-title mb-40">
                            <div class="sub-text style4-bg left">Let's Talk</div>
                            <h2 class="title pb-20">
                                Speak With Expert Engineers.
                            </h2>
                            <div class="desc">
                                Have an inquiry or some feedback for us ?<br />
                                Fill out the form.
                            </div>
                        </div>
                        <div class="services-wrap mb-25">
                            <div class="services-icon">
                                <img src="assets/images/choose/icons/style2/1.png" alt="" />
                            </div>
                            <div class="services-text">
                                <h3 class="title"><a href="#">Email</a></h3>
                                <p class="services-txt">General - info@hashedbit.com</p>
                                <p class="services-txt">General - hashedbit@gmail.com</p>
                                <p class="services-txt">HR - hr@hashedbit.com</p>
                            </div>
                        </div>
                        <div class="services-wrap mb-25">
                            <div class="services-icon">
                                <img src="assets/images/choose/icons/style2/2.png" alt="" />
                            </div>
                            <div class="services-text">
                                <h3 class="title"><a href="#">Call Us</a></h3>
                                <p class="services-txt">+91 9669787936</p>
                                <p class="services-txt">+91 9599171535</p>
                            </div>
                        </div>
                        <div class="services-wrap">
                            <div class="services-icon">
                                <img src="assets/images/choose/icons/style2/3.png" alt="" />
                            </div>
                            <div class="services-text">
                                <h3 class="title"><a href="#">Office Address</a></h3>
                                <p class="services-txt">Headquarter - Gurgaon</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="rs-contact mod1">
                            <div class="contact-wrap">
                                <div class="content-part mb-25">
                                    <h2 class="title mb-15">Schedule Appointment</h2>
                                    <p class="desc">We here to help you 24/7 with experts</p>
                                </div>
                                <div id="appointment-messages"></div>
                                <form id="appointment-form" onSubmit={handleSubmit}>
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-lg-12 mb-15">
                                                <input className="from-control" type="text" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                                            </div>
                                            <div class="col-lg-12 mb-15">
                                                <input className="from-control" type="text" id="name" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                                            </div>
                                            <div class="col-lg-12 mb-15">
                                                <input className="from-control" type="number" id="number" name="contact" placeholder="Contact" value={formData.contact} onChange={handleChange} required />
                                            </div>
                                            <div class="col-lg-12 mb-25">
                                                <input className="from-control" type="text" id="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
                                            </div>
                                            <div class="col-lg-12 mb-25">
                                                <input className="from-control" type="text" id="text" name="message" placeholder="Message" value={formData.message} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <input class="submit-btn" type="submit" value="Submit Now" />
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line-inner">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>

        </>
        
    )
}
export default Contact;

// import React from 'react'

// const Contact = () => {
//   return (
//     <div>







//     </div>
//   )
// }

// export default Contact
