import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import loadImage from '../../assets/img/giphy.gif';
import { useParams } from 'react-router';

const CourseRegistration = () => {

    const { coursename } = useParams();
    //console.log('courseid', coursename);

    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        mobile: "",
        // whatsapp_mobile: "",
        city: "",
        // hometown: "",
        college: "",
        // university: "",
        passing_year: "",
        branch: "",
        referredby: "",
        courseid: coursename.slice(0, 4)
    }

    const registerCourse = async (values) => {
        setLoader(true);
        console.log(values);
        const url = process.env.REACT_APP_LMS_API_URL + 'course/register';

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
            //const response = await axios.post(url, values);
            //console.log(response);

            if (response.status === 201) {
                setApistatus(true);
                setLoader(false);
                return {
                    error: false
                }
            }
            else {
                setError(true);
                setLoader(false);
                return {
                    error: true
                }
            }
        }
        catch (err) {
            setError(true);
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }

    return (
        <>

            <div class="rs-breadcrumbs img1">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Register to Join Us​</h1>
                    <h4 className="text-white">
                        Please, register here. We will connect with you for selection process / onboarding
                    </h4>
                    {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
                </div>
            </div>
            {/* <div className="services-area">
                <div className="container">

                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-12">
                            <div className="section-tittle text-center mb-80">
                                <h2>Register to Join Us​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <section class="services-area service-area services-padding">
                <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
                    <section className="contact-section rs-contact">
                        <div className="container">

                            <div className="row">
                                {/* <div className="col-12 text-center">
                            <h2 className="contact-title">Please, register here. We will connect with you for selection process / onboarding.</h2>
                        </div> */}

                                <div className='col-12 contact-wrap rounded mb-4 my-4'>

                                    <Formik
                                        initialValues={formikInitialValues}
                                        onSubmit={async (values, { resetForm }) => {
                                            //console.log(values);
                                            const { error } = await registerCourse(values);
                                            if (!error) {
                                                resetForm();
                                            };
                                        }}
                                    >
                                        <Form>
                                            <br></br>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Name</label>
                                                <div className='col-sm-8'>
                                                    <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Email</label>
                                                <div className='col-sm-8'>
                                                    <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Mobile</label>
                                                <div className='col-sm-8'>
                                                    <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                                </div>
                                            </div>

                                            {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">WhatsApp Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="whatsapp_mobile" type="text" className="form-control" placeholder="Enter WhatsaApp No" />
                                        </div>
                                    </div> */}

                                            {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Date of Birth</label>
                                        <div className='col-sm-8'>
                                            <Field name="dob" type="date" className="form-control" placeholder="" />
                                        </div>
                                    </div> */}

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Present Address</label>
                                                <div className='col-sm-8'>
                                                    <Field name="city" type="text" className="form-control" placeholder="Enter Present Address" required />
                                                </div>
                                            </div>

                                            {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Permanent Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="hometown" type="text" className="form-control" placeholder="Enter Permanent Address" />
                                        </div>
                                    </div> */}

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">College</label>
                                                <div className='col-sm-8'>
                                                    <Field name="college" type="text" className="form-control" placeholder="College Name" required />
                                                </div>
                                            </div>

                                            {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">University</label>
                                        <div className='col-sm-8'>
                                            <Field name="university" type="text" className="form-control" placeholder="University Name" />
                                        </div>
                                    </div> */}

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Passing Year</label>
                                                <div className='col-sm-8'>
                                                    <Field name="passing_year" type="text" className="form-control" placeholder="College Passing Year" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Branch / Stream</label>
                                                <div className='col-sm-8'>
                                                    <Field name="branch" type="text" className="form-control" Enter placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Where Did You Hear About Us</label>
                                                <div className='col-sm-8'>
                                                    <Field name="referredby" type="text" className="form-control" placeholder="LinkedIn/Instagram/WhatsApp/Others" />
                                                </div>
                                            </div>

                                            <Field name="courseid" type="hidden" className="form-control" placeholder="Enter Full Name" required />

                                            <br></br>
                                            <button type="submit" className='btn btn-success'>Register</button>

                                            <br></br>
                                            <br></br>
                                            <p>Note - We will connect within 3 days regarding further process. Do not register multiple times.</p>
                                            <br></br>
                                        </Form>
                                    </Formik>

                                    {
                                        apistatus && (
                                            <div className="alert alert-success" role="alert">
                                                You are successfully registered. Do not register multiple times. We will connect with you within 3 days.
                                            </div>
                                        )
                                    }

                                    {
                                        error && (
                                            <div className="alert alert-danger" role="alert">
                                                Server Error. Please, try after sometime.
                                            </div>
                                        )
                                    }
                                </div>

                                {loader && (
                                    <div className="loading">
                                        <img src={loadImage} alt="Loader" />
                                    </div>
                                )
                                }

                            </div>
                        </div>
                    </section>

                </div>
            </section>

        </>
    )
}

export default CourseRegistration;
