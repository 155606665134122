import React from 'react';
import { Link } from 'react-router-dom';

const CampusAmbassador = () => {
  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Campus Ambassador Program</h1>
          {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/home">Home</a>
                        </li>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/learning">learning</a>
                        </li>

                        <li>Campus Ambassador</li>
                    </ul> */}
        </div>
      </div>


      {/* <!-- Specialty Section Start --> */}
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="rs-feature">
                    <div class="row">
                      <div class="col-md-6 mt-20 pr-6  md-pb-20">
                        <div class="icon-box-area mb-20">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/1.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Promoting Company Events in campus
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="icon-box-area purple-bg">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/3.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Connecting Opportunities with college students
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="icon-box-area blue-bg mb-20">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/2.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Leading and organizing workshops & seminars
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="icon-box-area pink-bg">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/4.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Connecting TPO & Departments
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
                  {/* <div class="sec-title6 mb-30">
                    <div class="sub-text">Our Specialty</div>
                    <h2 class="title pb-20">
                      What's so Special About Braintech
                    </h2>
                    <div class="desc">
                      Bring to the table win-win survival strategies to ensure
                      dotted proactive domination. At the end of the day, going
                      forward, a new normal that has evolved
                    </div>
                  </div> */}
                  <ul class="rs-features-list">
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Eligible - College Students (Any Year/Stream/Branch) </span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Good communication and networking skills.</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Flexible timing / Complete Online</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Free access on HashedBit Learning Resources/Training Programs</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Internship Experience and Certificate</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Good exposure to Software Industry</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Internship and PPO opportunities</span>
                    </li>
                  </ul>

                  <div class="btn-part">
                    <Link
                      class="readon started get-new"
                      to="/learning/registration"
                    >
                      Interested ! Call at 95611-33644 for more details{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row my-5">
            <div className="companies pb-5">
              <div className="companies-title">
                <h2>Our Corporate Training Program Glimpses</h2>
              </div>
            </div>

            <div className="companies-images">
              <Swiper
                slidesPerView={3}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={image1} alt="Logo 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image2} alt="Logo 2" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image3} alt="Logo 3" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image4} alt="Logo 4" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image5} alt="Logo 5" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image6} alt="Logo 6" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}
        </div>
      </section>

    </>
  )
}

export default CampusAmbassador;
