import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

const CollegeInternship = () => {
  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Join College Internship</h1>
          <h4 className='text-white'>Join us as Intern from College !</h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a className="active" href="index.html">Home</a>
            </li>
            <li>
              <a href="http://localhost:3000/learning">Learning</a>

            </li>
            <li>
              College Internship
            </li>
          </ul> */}


        </div>
      </div>


      <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="rs-feature">
                <div class="row">
                  <div class="col-md-6 mt-20 pr-6  md-pb-20">
                    <div class="icon-box-area mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/1.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Full Stack Development</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area purple-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/3.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Software Development</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="icon-box-area blue-bg mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/2.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Business Development</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area pink-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/4.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Digital Marketing</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
              <div class="sec-title6 mb-30">
                <div class="sub-text"></div>
                <h2 class="title pb-20">
                  {/* What's so Special About Braintech */}
                </h2>
                <div class="desc">
                  {/* Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved */}
                </div>
              </div>
              <ul class="rs-features-list">
                <li><i class="fa fa-check"></i><span>For college freshers </span></li>
                <li><i class="fa fa-check"></i><span>2/6 months duration</span></li>
                <li><i class="fa fa-check"></i><span>Flexible timing</span></li>
                <li><i class="fa fa-check"></i><span>Complete WFH / Online Mode</span></li>
                <li><i class="fa fa-check"></i><span>Zero Charges</span></li>
                <li><i class="fa fa-check"></i><span>Free / Unpaid Internship for 2 months</span></li>
                <li><i class="fa fa-check"></i><span>Placement Assistance</span></li>
                <li><i class="fa fa-check"></i><span>PPO / Placement Opportunity</span></li>
                <li><i class="fa fa-check"></i><span>Mentorship by Industry Experts</span></li>
                {/* <li><i class="fa fa-check"></i><span>Technologies - MERN, JavaScript, React, PHP, Laravel, Wordpress, Mobile Applications, React Native</span></li> */}

              </ul>

              <div class="btn-part">
                <Link class="readon started get-new" to="/learning/registration/collegeInternship">Enroll Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <section class="services-area service-area services-padding">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>Join us as Intern from College</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
            <h5>Technology / Software / Recruitment / Sales / Marketing / Business Development / Digital Marketing</h5>
            </div>
          </div>


          <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Full Stack Development</div>
                       <div class="paragraph-22px round-point-text">1 month</div> 
                    </div><div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Software Development</div>
                       <div class="paragraph-22px round-point-text">1 month</div> 
                    </div></div><div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Business Development</div>
                       <div class="paragraph-22px round-point-text">1 month</div> 
                    </div>
                    <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Digital Marketing</div>
                       <div class="paragraph-22px round-point-text">1 month</div> 
                    </div>
                  </div>
                </div>
                <div class="round-text-wrapper">
                   <h2 id="test" class="heading-48px round-heading">Get Your dream job</h2> 
                  <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">For college freshers</div></div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">2/6 months duration</div>
                    </div>
                     <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Start from 30 October</div>
                    </div> 
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Flexible timing</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Complete WFH / Online Mode</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Zero Charges</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Free / Unpaid Internship for 2 months</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Placement Assistance</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">PPO / Placement Opportunity</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Mentorship by Industry Experts</div>
                    </div>
                     <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Technologies - MERN, JavaScript, React, PHP, Laravel, Wordpress, Mobile Applications, React Native</div>
                    </div> 
                  </div>

                  <Link to='/learning/registration/collegeinternship'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                   
                  </div>
                  </Link>
                  
                </div></div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}

export default CollegeInternship;
