import React from "react";
import { Link } from "react-router-dom";
import tickimage from "../../assets/img/learning/tick.svg";

import image1 from "../../assets/img/workshop/iitk.jpg";
import image2 from "../../assets/img/workshop/ieeenpl2.jpg";
import image3 from "../../assets/img/workshop/nitb.jpg";
import image4 from "../../assets/img/workshop/rits.jpg";
import image5 from "../../assets/img/workshop/sagar1.jpg";
import image6 from "../../assets/img/workshop/sagar2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const CorporateTraining = () => {
  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Corporate Training Programs</h1>
          <h4 className="text-white">
            Programming Languages / DevOps / Big Data / AI ML / Full Stack /
            Software Testing
          </h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
        </div>
      </div>
      <section class="services-area service-area services-padding">
        <div class="container">
          <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="rs-feature">
                    <div class="row">
                      <div class="col-md-6 mt-20 pr-6  md-pb-20">
                        <div class="icon-box-area mb-20">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/1.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Campus Training Programs Company Office
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="icon-box-area purple-bg">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/3.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Virtual Trainings Instructor led sessions
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="icon-box-area blue-bg mb-20">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/2.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Technology Seminars Online / Offline
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="icon-box-area pink-bg">
                          <div class="box-inner">
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/4.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="content-part">
                              <h4 class="title">
                                <a href="#">
                                  Technical Conferences Technology Expert
                                  Lectures
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
                  {/* <div class="sec-title6 mb-30">
                    <div class="sub-text">Our Specialty</div>
                    <h2 class="title pb-20">
                      What's so Special About Braintech
                    </h2>
                    <div class="desc">
                      Bring to the table win-win survival strategies to ensure
                      dotted proactive domination. At the end of the day, going
                      forward, a new normal that has evolved
                    </div>
                  </div> */}
                  <ul class="rs-features-list">
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Online/Offline Training Programs </span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Customised Programs as per requirement</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Flexible durations</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Sessions by Industry Experts</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Hands On Sessions</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Guest Lectures by Technology Experts</span>
                    </li>
                    <li>
                      <i class="fa fa-check"></i>
                      <span>Latest Technology based content</span>
                    </li>
                  </ul>

                  <div class="btn-part">
                    <Link
                      class="readon started get-new"
                      to=""
                    >
                      Interested ! Call at 96697-87936 for more details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row my-5">
            <div className="companies pb-5">
              <div className="companies-title">
                <h2>Our Corporate Training Program Glimpses</h2>
              </div>
            </div>

            <div className="companies-images">
              <Swiper
                slidesPerView={3}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={image1} alt="Logo 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image2} alt="Logo 2" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image3} alt="Logo 3" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image4} alt="Logo 4" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image5} alt="Logo 5" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image6} alt="Logo 6" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}
        </div>
      </section>
      <div class="rs-project bg6 style2 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            {/* <span class="sub-text style-bg white-color">Projects</span> */}
            <h2 class="title title2 white-color">
              Our Corporate Training Program Glimpses
            </h2>
          </div>
          {/* <div
            class="rs-carousel owl-carousel"
            data-loop="true"
            data-items="3"
            data-margin="30"
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout="5000"
            data-smart-speed="800"
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-center-mode="false"
            data-mobile-device="1"
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
            data-ipad-device="2"
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-ipad-device2="2"
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-md-device="3"
            data-md-device-nav="true"
            data-md-device-dots="false"
          > */}
          {/* <div className="companies-images">
                <div class="project-item">
                  <div class="project-img">
                    <a href="#">
                      <img src={image1} alt="Logo 1" />
                    </a>
                  </div>
                </div>

                <div class="project-item">
                  <div class="project-img">
                    <a href="#">
                      <img src={image2} alt="Logo 2" />
                    </a>
                  </div>
                </div>

                <div class="project-item">
                  <div class="project-img">
                    <a href="#">
                      <img src={image6} alt="Logo 6" />
                    </a>
                  </div>
                </div>
              </div> */}
          <div className="companies-images">
            <Swiper
              slidesPerView={3}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={image1} alt="Logo 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={image2} alt="Logo 2" />
              </SwiperSlide>
              {/* <SwiperSlide><img src={image3} alt="Logo 3" /></SwiperSlide> */}
              {/* <SwiperSlide><img src={image4} alt="Logo 4" /></SwiperSlide> */}
              {/* <SwiperSlide><img src={image5} alt="Logo 5" /></SwiperSlide> */}
              <SwiperSlide>
                <img src={image6} alt="Logo 6" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CorporateTraining;
