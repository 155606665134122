import React from 'react'
import { Link } from 'react-router-dom';

const Products = () => {
    return (


        <>

            <div className="rs-breadcrumbs img2">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title">Products</h1>

                </div>
            </div>




            <div className="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
                <div className="container">
                    <div className="sec-title2 text-center mb-45">
                        <span className="sub-text">Services</span>
                        <h2 className="title testi-title">
                            Some of Our Products
                        </h2>
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-6 mb-20">
                        <Link to="/products/schoolmanagement">
                            <div className="services-item">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="software-development.html">School Management Software</a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        School Management Software
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        01
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        
                        
                        <div className="col-lg-4 col-md-6 mb-20">
                        <Link to="/products/hospitalmanagement">
                            <div className="services-item pink-bg">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="web-development.html">Hospital Management Software </a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        Hospital Management Software
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        02
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        
                        
                        <div className="col-lg-4 col-md-6 mb-20">
                        <Link to="/products/employeemanagement">
                            <div className="services-item aqua-bg">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="analytic-solutions.html">Employee Management System</a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        Employee Management System
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        03
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                       
                        
                        <div className="col-lg-4 col-md-6 sm-mb-20">
                        <Link to="/products/hotelmanagement">
                            <div className="services-item paste-bg">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="cloud-and-devops.html">Hotel Management Software</a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        Hotel Management Software
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        04
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        
                        
                        <div className="col-lg-4 col-md-6 sm-mb-20">
                        <Link to="">
                            <div className="services-item purple-bg">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="product-design.html">Learning Management Solution</a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        Learning Management Solution
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        05
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        
                        
                        <div className="col-lg-4 col-md-6">
                        <Link to="">
                            <div className="services-item green-bg">
                                <div className="services-icon">
                                    <div className="image-part">
                                        <img className="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                                        <img className="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="services-text">
                                        <h3 className="title"><a href="web-development.html">Online Examination System</a></h3>
                                    </div>
                                    <div className="services-desc">
                                        <p>
                                        Online Examination System
                                        </p>
                                    </div>
                                    <div className="serial-number">
                                        06
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>



        </>

    )
}

export default Products
