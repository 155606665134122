import React from "react";
import { useState } from "react";
import { Formik, Field, Form } from "formik";

const CareerForm = () => {

    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const [careerFormStatus, setCareerFormStatus] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        mobile: "",
        whatsapp_mobile: "",
        city: "",
        hometown: "",
        college: "",
        university: "",
        passing_year: "",
        branch: "",
        linkedinid: "",
        githubid: "",
        referredby: "",
        currentcompany: "",
        yearsOfExperience: "",
        currentDesignation: "",
        noticePeriod: "",
        currentSalary: "",
        skills: "",
        dob: "",
        workLocation: "",
        typePartFull: "",
        typeFullIntern: "",
        jobType: "",
    }
    //uploadCV

    const submitCareer = async (values) => {
        setLoader(true);
        console.log(values);
        const url = process.env.REACT_APP_API_URL + 'career/register';

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
            //const response = await axios.post(url, values);
            //console.log(response);

            if (response.status === 200) {
                setApistatus(true);
                setLoader(false);
                return {
                    error: false
                }
            }
            else {
                setError(true);
                setLoader(false);
                return {
                    error: true
                }
            }
        }
        catch (err) {
            setError(true);
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }


    // const [selectedFile, setSelectedFile] = useState(null);

    return (
        <>

            <div class="rs-breadcrumbs img1">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Start Your Career With Us</h1>
                    <h4 className="text-white">
                        Please, register here. we will get back if we have any matching opportunity.
                    </h4>
                    {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Home</a>
                        </li>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Career</a>
                        </li>

                        <li>Join Us for other Openings</li>
                    </ul> */}
                </div>
            </div>



            {/* <!-- Counter Section Start --> */}
            <div class="rs-contact-wrap bg5 pt-120 pb-390 md-pt-80">
                <div class="container">
                    <div class="sec-title2 text-center mb-30">
                        <span class="sub-text style-bg white-color">Career</span>
                        <h2 class="title white-color">
                            Start Your Career With Us
                        </h2>
                    </div>
                </div>
            </div>
            {/* <!-- Counter Section End --> */}

            {/* <!-- Video Section End --> */}
            <div class="rs-video-wrap style2 inner  md-pb-80">
                <div class="container">
                    <div class="row margin-0 gray-color">
                        {/* <div class="col-lg-6 padding-0">
                            <div class="video-item">
                                <div class="rs-videos">
                                    <div class="animate-border main-home style2">
                                        <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                                            <i class="fa fa-play"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="col-lg-12 padding-0">
                            {/* <div class="rs-requset">
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="https://rstheme.com/products/html/braintech/mailer.php">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="first_name" name="first_name" placeholder="First Name" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="last_name" name="last_name" placeholder="Last Name" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">

                                                <select name="bussiness_divion" class="from-control" type="dropdown" id="bussiness_division" placeholder="Bussiness division" required="" >
                                                    <optgroup label="Tech Ops" >
                                                        <option value="Tech Ops" selected>Tech Ops</option>
                                                        <option value="sales">sales</option>
                                                        <option value="marketing">marketing</option>
                                                        <option value="finance">finance</option>
                                                        <option value="software developmen">software development</option>
                                                        <option value="Ops" >Ops</option>
                                                        <option value="legal">legal</option>
                                                        <option value="bussiness department">bussiness department</option>
                                                        <option value="HR">HR</option>
                                                        <option value="Low code / No code">Low code / No code</option>
                                                        <option value="consulting">consulting</option>
                                                    </optgroup>

                                                </select>
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="expected_salary" name="expected_salary" placeholder="Expected Stipend" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="experience" name="experience" placeholder="Experience" required="" />
                                            </div>
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <select name="preferred_location" class="from-control" type="dropdown" id="preferred_location" placeholder="Preferred location" required="" >

                                                    <option value="preferred-location" selected>Preferred work location</option>
                                                    <option value="noida" >Noida</option>
                                                    <option value="new Delhi">New Delhi</option>
                                                    <option value="gurugram">Gurugram</option>



                                                </select>
                                            </div>

                                            <div class="col-lg-12 mb-30 col-md-6 col-sm-6 ">

                                                <label htmlFor="upload_file" style={{ display: "inline-block", padding: "10px 20px", border: "2px solid", borderRadius: "30px", borderColor: selectedFile ? "green" : "#007bff", backgroundColor: "#F6F7F9", color: "black", cursor: "pointer" }}>
                                                    {selectedFile ? selectedFile.name : "Upload your resume"}
                                                    <input
                                                        class="form control"
                                                        type="file"
                                                        id="upload_file"
                                                        name="upload_file"
                                                        style={{ display: "none" }}
                                                        onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            setSelectedFile(file);
                                                        }}
                                                        required
                                                    />
                                                    {selectedFile && (
                                                        <div class=" ml-10" style={{ display: "inline-block" }} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                </label>

                                            </div>



                                        </div>
                                        <div class="btn-part">
                                            <input class="submit sub-small" type="submit" value="Submit Now" />
                                        </div>
                                    </fieldset>
                                </form>
                            </div> */}



                            <section className="contact-section">
                                <div className="container">

                                    <div className="row">
                                        {/* <div className="col-12">
                            <h2 className="contact-title">Please, register here. we will get back if we have any matching opportunity.</h2>
                        </div> */}



                                        <div className='col-12'>

                                            <Formik
                                                initialValues={formikInitialValues}
                                                onSubmit={async (values, { resetForm }) => {
                                                    //console.log(values);
                                                    const { error } = await submitCareer(values);
                                                    if (!error) {
                                                        resetForm();
                                                    };
                                                }}
                                            >
                                                <Form>
                                                    <br></br>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Name</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Email</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Mobile</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">WhatsApp Mobile</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="whatsapp_mobile" type="text" className="form-control" placeholder="Enter WhatsaApp No" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Date of Birth</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="dob" type="date" className="form-control" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Present Address</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="city" type="text" className="form-control" placeholder="Enter Present Address" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Permanent Address</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="hometown" type="text" className="form-control" placeholder="Enter Permanent Address" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">College</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="college" type="text" className="form-control" placeholder="College Name" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">University</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="university" type="text" className="form-control" placeholder="University Name" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Passing Year</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="passing_year" type="text" className="form-control" placeholder="College Passing Year" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Branch / Stream</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="branch" type="text" className="form-control" Enter placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Linkedin Profile URL</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="linkedinid" type="text" className="form-control" placeholder="Your Linkedin Profile URL (if any)" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Github Profile URL</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="githubid" type="text" className="form-control" placeholder="Your Github Profile URL (if any)" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Where Did You Hear About Us</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="referredby" type="text" className="form-control" placeholder="LinkedIn/Instagram/WhatsApp/Others" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Current Company</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="currentcompany" type="text" className="form-control" placeholder="Freshers can leave it blank" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Years of Experience</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="yearsOfExperience" type="text" className="form-control" placeholder="e.g. - 1.3 Years" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Current Designation</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="currentDesignation" type="text" className="form-control" placeholder="Current Designation" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Notice Period (In Months)</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="noticePeriod" type="text" className="form-control" placeholder="Notice Period (In Months)" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Current Salary</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="currentSalary" type="text" className="form-control" placeholder="In LPA" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Mention Your Skills</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="skills" type="text" className="form-control" placeholder="Write name of your major skills" required />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Work Location Preferences</label>
                                                        <div className='col-sm-8'>
                                                            <Field name="workLocation" type="text" className="form-control" placeholder="Write name of preferred cities. Write Remote if looking for WFH" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Full Time / Part Time</span>
                                                        <div className='col-sm-8'>
                                                            <span role="group" aria-labelledby="my-radio-group">
                                                                <label>
                                                                    <Field type="checkbox" name="typePartFull" value="Full Time" className="form-check-input" />
                                                                    Full Time
                                                                </label>
                                                                <label>
                                                                    <Field type="checkbox" name="typePartFull" value="Part Time" className="form-check-input" />
                                                                    Part Time
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Job / Internship</span>
                                                        <div className='col-sm-8'>
                                                            <span role="group" aria-labelledby="my-radio-group">
                                                                <label>
                                                                    <Field type="checkbox" name="typeFullIntern" value="Job" className="form-check-input" />
                                                                    Job
                                                                </label>
                                                                <label>
                                                                    <Field type="checkbox" name="typeFullIntern" value="Internship" className="form-check-input" />
                                                                    Internship
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Job Domain</span>
                                                        <div className='col-sm-8'>
                                                            <span role="group" aria-labelledby="my-radio-group">
                                                                <label>
                                                                    <Field type="radio" name="jobType" value="Technology" className="form-check-input" />
                                                                    Technology
                                                                </label>
                                                                <label>
                                                                    <Field type="radio" name="jobType" value="Business Development" className="form-check-input" />
                                                                    Business Development
                                                                </label>
                                                                <label>
                                                                    <Field type="radio" name="jobType" value="HR" className="form-check-input" />
                                                                    HR
                                                                </label>
                                                                <label>
                                                                    <Field type="radio" name="jobType" value="Marketing" className="form-check-input" />
                                                                    Marketing
                                                                </label>
                                                                <label>
                                                                    <Field type="radio" name="jobType" value="Trainer/Teaching" className="form-check-input" />
                                                                    Trainer/Teaching
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <br></br>
                                                    <button type="submit" className='btn btn-success'>Register</button>

                                                    <br></br>
                                                    <br></br>
                                                </Form>
                                            </Formik>

                                            {
                                                apistatus && (
                                                    <div className="alert alert-success" role="alert">
                                                        You are successfully registered.
                                                    </div>
                                                )
                                            }

                                            {
                                                error && (
                                                    <div className="alert alert-danger" role="alert">
                                                        Server Error. Please, try after sometime.
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {loader && (
                                            <div className="loading">
                                                {/* <img src={loadImage} alt="Loader" /> */}
                                                Loading...
                                            </div>
                                        )
                                        }

                                        <div className="col-12 text-center">
                                            <h3 className="contact-title"><br></br>Or<br></br>Drop CV at hashedbitinnovations@gmail.com<br></br></h3>
                                        </div>

                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>




            {/* <section className="contact-section">
                <div className="container">

                    <div className="row">
                        



                        <div className='col-12'>

                            <Formik
                                initialValues={formikInitialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    //console.log(values);
                                    const { error } = await submitCareer(values);
                                    if (!error) {
                                        resetForm();
                                    };
                                }}
                            >
                                <Form>
                                    <br></br>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Name</label>
                                        <div className='col-sm-8'>
                                            <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Email</label>
                                        <div className='col-sm-8'>
                                            <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">WhatsApp Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="whatsapp_mobile" type="text" className="form-control" placeholder="Enter WhatsaApp No" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Date of Birth</label>
                                        <div className='col-sm-8'>
                                            <Field name="dob" type="date" className="form-control" placeholder="" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Present Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="city" type="text" className="form-control" placeholder="Enter Present Address" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Permanent Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="hometown" type="text" className="form-control" placeholder="Enter Permanent Address" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">College</label>
                                        <div className='col-sm-8'>
                                            <Field name="college" type="text" className="form-control" placeholder="College Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">University</label>
                                        <div className='col-sm-8'>
                                            <Field name="university" type="text" className="form-control" placeholder="University Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Passing Year</label>
                                        <div className='col-sm-8'>
                                            <Field name="passing_year" type="text" className="form-control" placeholder="College Passing Year" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Branch / Stream</label>
                                        <div className='col-sm-8'>
                                            <Field name="branch" type="text" className="form-control" Enter placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Linkedin Profile URL</label>
                                        <div className='col-sm-8'>
                                            <Field name="linkedinid" type="text" className="form-control" placeholder="Your Linkedin Profile URL (if any)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Github Profile URL</label>
                                        <div className='col-sm-8'>
                                            <Field name="githubid" type="text" className="form-control" placeholder="Your Github Profile URL (if any)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Where Did You Hear About Us</label>
                                        <div className='col-sm-8'>
                                            <Field name="referredby" type="text" className="form-control" placeholder="LinkedIn/Instagram/WhatsApp/Others" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Company</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentcompany" type="text" className="form-control" placeholder="Freshers can leave it blank" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Years of Experience</label>
                                        <div className='col-sm-8'>
                                            <Field name="yearsOfExperience" type="text" className="form-control" placeholder="e.g. - 1.3 Years" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Designation</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentDesignation" type="text" className="form-control" placeholder="Current Designation" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Notice Period (In Months)</label>
                                        <div className='col-sm-8'>
                                            <Field name="noticePeriod" type="text" className="form-control" placeholder="Notice Period (In Months)" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Current Salary</label>
                                        <div className='col-sm-8'>
                                            <Field name="currentSalary" type="text" className="form-control" placeholder="In LPA" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mention Your Skills</label>
                                        <div className='col-sm-8'>
                                            <Field name="skills" type="text" className="form-control" placeholder="Write name of your major skills" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Work Location Preferences</label>
                                        <div className='col-sm-8'>
                                            <Field name="workLocation" type="text" className="form-control" placeholder="Write name of preferred cities. Write Remote if looking for WFH" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Full Time / Part Time</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="checkbox" name="typePartFull" value="Full Time" className="form-check-input" />
                                                    Full Time
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="typePartFull" value="Part Time" className="form-check-input" />
                                                    Part Time
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Looking for Job / Internship</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="checkbox" name="typeFullIntern" value="Job" className="form-check-input" />
                                                    Job
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="typeFullIntern" value="Internship" className="form-check-input" />
                                                    Internship
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <span id="my-radio-group" className="col-sm-4 col-form-label">Job Domain</span>
                                        <div className='col-sm-8'>
                                            <span role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                    <Field type="radio" name="jobType" value="Technology" className="form-check-input" />
                                                    Technology
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Business Development" className="form-check-input" />
                                                    Business Development
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="HR" className="form-check-input" />
                                                    HR
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Marketing" className="form-check-input" />
                                                    Marketing
                                                </label>
                                                <label>
                                                    <Field type="radio" name="jobType" value="Trainer/Teaching" className="form-check-input" />
                                                    Trainer/Teaching
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <br></br>
                                    <button type="submit" className='btn btn-success'>Register</button>

                                    <br></br>
                                    <br></br>
                                </Form>
                            </Formik>

                            {
                                apistatus && (
                                    <div className="alert alert-success" role="alert">
                                        You are successfully registered.
                                    </div>
                                )
                            }

                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        Server Error. Please, try after sometime.
                                    </div>
                                )
                            }
                        </div>

                        {loader && (
                            <div className="loading">
                                
                                Loading...
                            </div>
                        )
                        }

                        <div className="col-12 text-center">
                            <h3 className="contact-title"><br></br>Or<br></br>Drop CV at hashedbitinnovations@gmail.com<br></br></h3>
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <!-- Video Section End --> */}

        </>
    )
}

export default CareerForm;