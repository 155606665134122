import React from "react";
import { Link } from "react-router-dom";
import tickimage from "../../assets/img/learning/tick.svg";

import image1 from "../../assets/img/workshop/iitk.jpg";
import image2 from "../../assets/img/workshop/ieeenpl2.jpg";
import image3 from "../../assets/img/workshop/nitb.jpg";
import image4 from "../../assets/img/workshop/rits.jpg";
import image5 from "../../assets/img/workshop/sagar1.jpg";
import image6 from "../../assets/img/workshop/sagar2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const CollegeWorkshop = () => {
    return (
        <>
            <div class="rs-breadcrumbs img1">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">College Workshop Program</h1>
                    <h4 className="text-white">
                        Training Programs / Workshop / Seminars / Webinars
                    </h4>
                    {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">
                                Home
                            </a>
                        </li>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">
                                learning
                            </a>
                        </li>
                        <li>CorporateTraining</li>
                    </ul> */}
                </div>
            </div>
            <section class="services-area service-area services-padding">
                <div class="container">
                    {/* <div class="row d-flex justify-content-center">
                        <div class="col-lg-12">
                            <div class="section-tittle text-center">
                                <h2>Our College Workshop Program</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
                            <h5>Training Programs / Workshop / Seminars / Webinars</h5>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-12">


                            {/* updated code here  */}
                            <div class="rs-specialty bg18 pt-170 pb-100 md-pb-100">
                                <div class="container">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="rs-feature">
                                                <div class="row">
                                                    <div class="col-md-6 mt-20 pr-6 md-pb-20">
                                                        <div class="icon-box-area mb-20">
                                                            <div class="box-inner">
                                                                <div class="icon-area">
                                                                    <a href="#">
                                                                        <img
                                                                            src=".assets/images/features/icons/1.png"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div class="content-part">
                                                                    <h4 class="title">
                                                                        <a href="#">
                                                                            Training Programs College Campus / Online
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="icon-box-area purple-bg">
                                                            <div class="box-inner">
                                                                <div class="icon-area">
                                                                    <a href="#">
                                                                        <img
                                                                            src=".assets/images/features/icons/3.png"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div class="content-part">
                                                                    <h4 class="title">
                                                                        <a href="#">Workshops 1-2 Days</a>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="icon-box-area blue-bg mb-20">
                                                            <div class="box-inner">
                                                                <div class="icon-area">
                                                                    <a href="#">
                                                                        <img
                                                                            src=".assets/images/features/icons/2.png"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div class="content-part">
                                                                    <h4 class="title">
                                                                        <a href="#">
                                                                            Seminars College Campus / Online
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="icon-box-area pink-bg">
                                                            <div class="box-inner">
                                                                <div class="icon-area">
                                                                    <a href="#">
                                                                        <img
                                                                            src=".assets/images/features/icons/4.png"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div class="content-part">
                                                                    <h4 class="title">
                                                                        <a href="#">
                                                                            Coding Contests College Campus / Online
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
                                            <ul class="rs-features-list">
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Free Workshop / Seminars for Colleges </span>
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Customised Training Programs</span>
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Engineering / Management</span>
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Sessions by Industry Experts</span>
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Placement Ready Content</span>
                                                </li>
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <span>Hands On Sessions</span>
                                                </li>
                                            </ul>

                                            <div class="btn-part">
                                                <Link
                                                    class="readon started get-new"
                                                    to=""
                                                >
                                                    Interested ! Call at 96697-87936 for more details
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row my-5">
                        <div className="companies pb-5">
                            <div className="companies-title">
                                <h2 className="text-center justify-content-center">
                                    Our College Workshop Glimpses
                                </h2>
                               
                            </div>
                        </div>
                        <div className="companies-images">
                            <Swiper
                                slidesPerView={3}
                                pagination={{
                                    type: "fraction",
                                }}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <img src={image1} alt="Logo 1" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={image2} alt="Logo 2" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={image3} alt="Logo 3" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={image4} alt="Logo 4" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={image5} alt="Logo 5" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={image6} alt="Logo 6" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default CollegeWorkshop;
