import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';

const CoursePlacement = () => {
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Placement Batch - Full Stack Development</h1>
          <h4 className="text-white">
            Learn to build web applications on MERN Stack and secure your career in top startups and companies.
          </h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
        </div>
      </div>
      <section class="services-area service-area services-padding">
        {/* <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>Placement Batch - Full Stack Development</h2>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
              <h5>Learn to build web applications on MERN Stack and secure your career in top startups and companies.</h5>
            </div>
          </div>
        </div> */}



        <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="rs-feature">
                  <div class="row">
                    <div class="col-md-6 mt-20 pr-6  md-pb-20">
                      <div class="icon-box-area mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/1.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Web Fundamentals (HTML, CSS & JS)
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area purple-bg">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/3.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Data Structure & Soft Skills
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="icon-box-area blue-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/2.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                React JS, Redux
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area pink-bg">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/4.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Node JS, Express, MySQL, MongoDB
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
                <div class="sec-title6 mb-30">
                  <div class="sub-text">Our Specialty</div>
                  {/* <h2 class="title pb-20">
                    What's so Special About Braintech
                  </h2>
                  <div class="desc">
                    Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved
                  </div> */}
                </div>
                <ul class="rs-features-list">
                  <li><i class="fa fa-check"></i><span>For college final years / graduates</span></li>
                  <li><i class="fa fa-check"></i><span>4 months classroom program</span></li>
                  <li><i class="fa fa-check"></i><span>2 months internship experience</span></li>
                  <li><i class="fa fa-check"></i><span>Timing - Evening - 7-8pm</span></li>
                  <li><i class="fa fa-check"></i><span>Online Instructor led Classes</span></li>
                  <li><i class="fa fa-check"></i><span>Projects based Learning</span></li>
                  <li><i class="fa fa-check"></i><span>One-2-one mentorship </span></li>
                  <li><i class="fa fa-check"></i><span>Mock Interviews</span></li>
                  <li><i class="fa fa-check"></i><span>Soft Skills and Aptitude Prep</span></li>
                  <li><i class="fa fa-check"></i><span>100% Placement Guarantee</span></li>
                  <li><i class="fa fa-check"></i><span>Training and Internship Certificate</span></li>
                  <li><i class="fa fa-check"></i><span>Pay in monthly EMIs after classes/placement</span></li>
                  <li><i class="fa fa-check"></i><span>Zero Charges</span></li>
                  <li><i class="fa fa-check"></i><span>Total Fee (50% Discount) - <span style={{ textDecoration: 'line-through', color: 'red' }}>20,000</span>&nbsp;10,000</span></li>
                  
                </ul>

                <div class="btn-part">
                  <Link class="readon started get-new" to="/learning/registration/placement">Enroll Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>




        {/* <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid"> */}
        {/* <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Web Fundamentals (HTML, CSS &amp; JS)</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div> */}
        {/* <div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Data Structure &amp; Soft Skills</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div> */}
        {/* </div><div class="w-layout-grid round-column-grid"> */}
        {/* <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">React JS, Redux</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div> */}
        {/* <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Node JS, Express, MySQL, MongoDB</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div> */}
        {/* </div>
                </div> */}
        {/* <div class="round-text-wrapper">
                   {/* <h2 id="test" class="heading-48px round-heading">Get Your dream job</h2>  */}
        {/* <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">For college final years / graduates</div></div>
                      <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">4 months classroom program</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">2 months internship experience</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Start Date - 8th April</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Timing - Evening - 7-8pm</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Online Instructor led  Classes</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Projects based Learning</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">One-2-one mentorship</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Mock Interviews</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Soft Skills and Aptitude Prep</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">100% Placement Guarantee</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Training and Internship Certificate</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Pay in monthly EMIs after classes/placement</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Total Fee (50% Discount) - <span style={{textDecoration: 'line-through', color: 'red'}}>20,000</span>&nbsp;10,000 </div>
                    </div>
                  </div> */}

        {/* <p className='text-primary'>New Batch starting from 22nd April 2024</p>

                  <Link to='/learning/registration/placement'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                   
                  </div>
                  </Link>  */}

        {/* </div></div>   */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </section>
    </>
  )
}

export default CoursePlacement 
